import React, { useContext } from 'react';

//Chart js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { GRAPH_OPTIONS } from '../../../constants/graph_constants';
import { DashboardContext } from '../../../Context/DashboardContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Graph2 = () => {
    const { data: { graphs: { two } } } = useContext(DashboardContext);

    return (
        <div style={{ width: '100%', height: '350px', overflow: 'hidden' }}>
            <Bar options={GRAPH_OPTIONS} data={two} style={{ maxWidth: '100%' }} />
        </div>
    );
};

export default Graph2;