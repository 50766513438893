import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RouterContext } from '../../Context/RouterContext';

import { oauthToken } from '../../actions/sso';

import { Paper, Alert } from '@mui/material';

const Auth = () => {
    const navigate = useNavigate();
    const { item: { email }, setItem } = useContext(RouterContext);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getCodeFromURL();
    }, []);

    useEffect(() => {
        if (email !== '')
            navigate('/admin');
    }, [email]);

    const getCodeFromURL = async () => {
        const code = searchParams.get("code");
        if (code) {
            const req = await oauthToken(code, setItem);
            if (!req.status)
                navigate(`/${req.msg}`);
        }
    };

    return (
        <Paper>
            <Alert variant="outlined" severity="info">
                Autenticando...
            </Alert>
        </Paper>
    );
};

export default Auth;