import Swal from 'sweetalert2';

export const basicMsg = (icon, title, text) => {
    Swal.fire({
        icon, title,
        text, timer: 4000
    });
};

export const simpleMsg = (text) => {
    Swal.fire(text);
};