import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import './Index.css';
import logo from '../../Assets/Grupo-NIN_empresas.png';

import { simpleMsg } from '../../helpers/messagesHelper';

//MUI
import {
    Button, Box, Container, Typography
    // Checkbox, Divider, FormControl, FormControlLabel,
    // FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, 
} from '@mui/material'
import { styled } from '@mui/material/styles';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import EmailIcon from '@mui/icons-material/Email';
// import KeyIcon from '@mui/icons-material/Key';

const ColorButton = styled(Button)(({ theme }) => ({
    color: '#fff',
    backgroundColor: '#283A49',
    textTransform: 'none',
    width: '100%',
    height: '50px',
    '&:hover': {
        backgroundColor: '#374a5a',
    },
}));

// const show_err = { display: "none" };
// const hide_err = { display: "flex" };

// const init = {
//     email: '',
//     password: '',
// };

// const initErr = {
//     email_err: false,
//     password_err: false,
// };

// const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

const Index = () => {
    // mensaje
    const { msg } = useParams();

    // const [values, setValues] = useState(init);
    // const { email, password } = values;

    // const [inputErr, setInputErr] = useState(initErr);
    // const { email_err, password_err } = inputErr;

    // const email_ref = useRef();
    // const password_ref = useRef();

    useEffect(() => {
        if (msg && msg !== '') {
            if (msg === 'site-err')
                simpleMsg('Intente iniciar de nuevo');
            else if (['ax-err', 'server-err'].includes(msg))
                simpleMsg('Incidente en el servidor');
        }
    }, [msg]);

    // const handleChange = ({ target }) => {
    //     const name = target.name;
    //     const value = target.value;
    //     setValues({ ...values, [name]: value });
    //     switch (name) {
    //         case 'email':
    //             setInputErr({
    //                 ...inputErr,
    //                 email_err: (value.length < 6 ||
    //                     value.length > 100) ? true : false
    //             });
    //             break;
    //         case 'password':
    //             setInputErr({
    //                 ...inputErr,
    //                 password_err: (value.length < 5 ||
    //                     value.length > 60) ? true : false
    //             });
    //             break;
    //         default:
    //             break;
    //     }
    // };

    // const formValidator = () => {
    //     const email_len = email.trim().length;
    //     const password_len = password.trim().length;
    //     if (email_len > 5 && email_len <= 100 && email_regex.test(email)) {
    //         if (password_len > 4 && password_len <= 60)
    //             return true;
    //         else {
    //             password_ref.current.select();
    //             setInputErr({
    //                 ...inputErr,
    //                 password_err: true
    //             });
    //         }
    //     } else {
    //         email_ref.current.select();
    //         setInputErr({
    //             ...inputErr,
    //             email_err: true
    //         });
    //     }
    //     return false;
    // };

    // const [showPassword, setShowPassword] = useState(false);

    // const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `${process.env.REACT_APP_OAUTH_AUTHORIZATION_ENDPOINT}?client_id=${process.env.REACT_APP_MICROSOFT_APP_ID}&scope=User.Read&response_type=code`;
    };

    return (
        <div className='bg_login'>
            <Container maxWidth='lg' sx={{ py: 10, pb: 8 }}>
                <div className='form_login'>

                    <Typography variant='h5' sx={{ fontWeight: 'bold' }} gutterBottom>
                        Iniciar sesión
                    </Typography>
                    {/* <Typography variant='body1' color='text.secondary' sx={{ fontStyle: 'italic' }}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla blandit sapien a dui consequat, at.
                    </Typography>
                    <Divider sx={{ my: 4 }} />
                    
                    <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                        <InputLabel>Correo electrónico</InputLabel>
                        <OutlinedInput
                            id='email_input'
                            placeholder="Correo electrónico"
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailIcon fontSize='small' />
                                </InputAdornment>
                            }
                            label="Correo electrónico"
                            error={email_err}
                            inputRef={email_ref}
                            name='email'
                            value={email}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={email_err ? hide_err : show_err}>
                            Ingrese su correo electrónico
                        </FormHelperText>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
                        <InputLabel>Contraseña</InputLabel>
                        <OutlinedInput
                            id='pass_input'
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Contraseña"
                            startAdornment={
                                <InputAdornment position="start">
                                    <KeyIcon fontSize='small' />
                                </InputAdornment>
                            }
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Contraseña"
                            error={password_err}
                            inputRef={password_ref}
                            name='password'
                            value={password}
                            onChange={handleChange}
                        />
                        <FormHelperText error style={password_err ? hide_err : show_err}>
                            Ingrese su contraseña
                        </FormHelperText>
                    </FormControl>
                    <Box display='flex' justifyContent='space-between' gap='8px' flexWrap='wrap' mb={3.5}>
                        <Box sx={{ my: 'auto' }}>
                            <FormControlLabel
                                control={<Checkbox size='small' />}
                                label={<Typography variant="body2" color="textSecondary">Recordar correo</Typography>}
                                sx={{ fontSize: '10px' }}
                            />
                        </Box>
                        <Box sx={{ my: 'auto' }}>
                            <a href='#!' style={{ fontSize: '14px' }}>Olvidé mi contraseña</a>
                        </Box>
                    </Box> */}
                    <ColorButton variant='contained' onClick={handleSubmit}>
                        Acceder con cuenta de Microsoft
                    </ColorButton>
                    <Box sx={{ mt: 3 }}>
                        <img src={logo} alt={logo} width='100%' style={{ pointerEvents: 'none' }} />
                    </Box>
                </div>
            </Container>
        </div>
    );
};

export default Index;