//React
import { useContext } from 'react';

//Router dom
import { Navigate } from 'react-router-dom';

//Context
import { RouterContext } from '../Context/RouterContext';

export const PublicRoute = ({ children }) => {

    const { item } = useContext(RouterContext)

    const { email } = item;
    return (email !== '')
        ? <Navigate to="/admin" />
        : children
};
