import React from 'react';

//MUI
import { createTheme, ThemeProvider } from '@mui/material';

//Components
import AppRoute from './router/AppRoute';

//Context
import RouterContextProvider from './Context/RouterContext';
import DashboardContextProvider from './Context/DashboardContext';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1536,
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});


const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <DashboardContextProvider>
        <RouterContextProvider>
          <AppRoute />
        </RouterContextProvider>
      </DashboardContextProvider>
    </ThemeProvider>
  )
}

export default App