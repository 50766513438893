import React, { useState, useContext, useEffect } from 'react';
import { startOfDay, endOfDay, subWeeks, compareAsc } from 'date-fns';

import { DashboardContext } from '../../../Context/DashboardContext';

import { salesFilter } from '../../../actions/data';

//CSS
import './IndexAdmin.css';

//MUI
import { Box, Container, Grid, Typography, IconButton, Chip, Button, useMediaQuery } from '@mui/material';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SyncIcon from '@mui/icons-material/Sync';
import FilterListIcon from '@mui/icons-material/FilterList';

//Components
import Graph1 from '../DashboardComponents/Graph1';
import Graph2 from '../DashboardComponents/Graph2';
import Graph3 from '../DashboardComponents/Graph3';
import Graph4 from '../DashboardComponents/Graph4';
// import Graph5 from '../DashboardComponents/Graph5';
// import Graph6 from '../DashboardComponents/Graph6';
// import Graph7 from '../DashboardComponents/Graph7';
// import Graph8 from '../DashboardComponents/Graph8';
// import CategoriesFilter from '../Filters/CategoriesFilter';
import SidebarAdmin from '../SidebarAdmin/SidebarAdmin';
import Filters from '../Filters/Filters';

// const TooltipCustomized = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(() => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 160,
//   },
// }));

const FilterBtn = styled(Button)(({ theme }) => ({
  color: '#6C7E97',
  textTransform: 'none',
  backgroundColor: 'none',
}));

const RefreshBTN = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#52BE80',
  '&:hover': {
    backgroundColor: '#339b60',
  },
}));

const today = new Date();
const INITIAL_DATE = [
  subWeeks(startOfDay(today), 1),
  endOfDay(today)
];

const IndexAdmin = () => {
  const matches = useMediaQuery('(max-width: 991.5px)');
  const { data: { businessName, products, firstLoading, backup }, setData } = useContext(DashboardContext);
  // const tooltipIsOpen = true;
  // const matches = useMediaQuery('(max-width: 500px)');

  // lista de razón social
  const [businessNameDisplay, setBusinessNameDisplay] = useState([]);
  // expandir lista de razón social
  const [expandedData, setExpandedData] = useState(false);

  // lista de todos los productos y checkbox
  const [dataForDisplay, setDataForDisplay] = useState([]);
  // lista de productos seleccionados
  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
  // expandir lista de todos los productos
  const [expanded, setExpanded] = useState(false);
  // filtro por fecha
  const [filterDate, setFilterDate] = useState(INITIAL_DATE);
  // categoría seleccionada
  const [selectedCategory, setSelectedCategory] = useState('');

  // copiar arreglo de razón social
  useEffect(() => {
    setBusinessNameDisplay(expandedData ? (businessName) : businessName.slice(0, 7));
  }, [businessName, expandedData]);

  // copiar arreglo de productos
  useEffect(() => {
    setDataForDisplay(expanded ? (products) : products.slice(0, 7));
  }, [products, expanded]);

  // filtra productos seleccionados
  useEffect(() => {
    // if (dataForDisplay.find(e => e.checked)) // para evitar disparar consulta en useEffect de checkedCheckboxes
      setCheckedCheckboxes(
        dataForDisplay.filter(
          e => e.checked
        ).map(
          ({ product_id, product }) => ({ product_id, product })
        )
      );
  }, [dataForDisplay]);

  useEffect(() => {
    if (!firstLoading) { // se cargó la información por primera vez
      const businessNameFilter = businessNameDisplay.filter(e => e.checked);
      if (
        businessNameFilter.length > 0 || // razón social seleccionada
        compareAsc(INITIAL_DATE[0], filterDate[0]) !== 0 || // cambió fecha inicial
        compareAsc(INITIAL_DATE[1], filterDate[1]) !== 0 || // cambió fecha final
        checkedCheckboxes.length > 0 || // productos seleccionados
        selectedCategory !== '' // categoría seleccionada
      )
        salesFilter( // petición a BD
          businessNameFilter,
          filterDate, checkedCheckboxes, selectedCategory,
          setData
        );
      else
        setData(e => ({ ...e, ...backup }));
    }
  }, [businessNameDisplay, filterDate, checkedCheckboxes, selectedCategory]);

  // limpiar selección de productos
  const handleResetFilters = () => {
    setCheckedCheckboxes([]);
    setDataForDisplay(e => e.map(u => ({ ...u, checked: false })));
    selectedCategory('');
  };

  // deseleccionar producto de lista
  const unselectProduct = product_id => () => {
    setDataForDisplay(
      e => e.map(
        u => u.product_id === product_id ? ({ ...u, checked: false }) : u
      )
    );
    setCheckedCheckboxes(e => e.filter(u => u.product_id !== product_id));
  };

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(true);
  };

  return (
    <>
      <Box className='filters_mobile'>
        <FilterBtn onClick={handleDrawerToggle} endIcon={<FilterListIcon />}>Filtrar</FilterBtn>
      </Box>

      <Container maxWidth='xxxl' sx={{ mb: 10, mt: matches ? 4 : 8 }}>
        <Grid container spacing={4}>
          {/* Filtros */}
          <Grid item xl={2.5} lg={4} md={12} sm={12} xs={12}>

            <Box sx={{ mb: checkedCheckboxes.length >= 1 ? 3 : 0 }}>
              {
                checkedCheckboxes.length >= 1
                  ?
                  <>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', mb: 2 }}>
                      <Typography variant='body1' sx={{ fontWeight: 'bold', my: 'auto' }}>Filtros</Typography>
                      <Button size='small' sx={{ textTransform: 'none', my: 'auto' }} onClick={handleResetFilters}>Limpiar filtros</Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: '10px' }}>
                      {
                        checkedCheckboxes.map(({ product_id, product }) => (
                          <Chip key={product_id} label={ product }
                            size='small' color="primary" variant="outlined"
                            onDelete={ unselectProduct(product_id) }
                          />
                        ))
                      }
                    </Box>
                  </>
                  :
                  null
              }
            </Box>

            <Box className='filters_desktop'>
              <Filters
                businessNameDisplay={businessNameDisplay}
                setBusinessNameDisplay={setBusinessNameDisplay}
                expandedData={expandedData}
                setExpandedData={setExpandedData}
                filterDate={filterDate}
                setFilterDate={setFilterDate}
                dataForDisplay={dataForDisplay}
                setDataForDisplay={setDataForDisplay}
                expanded={expanded}
                setExpanded={setExpanded}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </Box>

          </Grid>

          {/* Contenido del dashboard */}
          <Grid item xl={9.5} lg={8} md={12} sm={12} xs={12}>
            {/* {
            matches
              ?
              <Accordion sx={{ mb: 5, boxShadow: '0 2px 4px 2px rgb(0 0 0 / 20%)' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  Filtros por categoría
                </AccordionSummary>
                <AccordionDetails>
                  <CategoriesFilter />
                </AccordionDetails>
              </Accordion>
              :
              <TooltipCustomized
                title={
                  <React.Fragment>
                    <Typography color="inherit" variant='body2' textAlign='center'>Filtrar gráficas por categorías de productos</Typography>
                  </React.Fragment>
                }
                open={tooltipIsOpen}
                placement="bottom"
              >
                <Box>
                  <CategoriesFilter />
                </Box>
              </TooltipCustomized>
          } */}

            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <RefreshBTN size="large">
                <SyncIcon fontSize='Inherit' />
              </RefreshBTN>
            </Box>

            <Grid container spacing={4}>

              <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Suma de Líneas de factura / cantidad */}
                      Cantidad de producto desplazado
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph1 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Suma de Venta antes de descuento
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph2 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Suma de Líneas de factura / subtotal */}
                      Venta con descuento ($)
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph3 />
                  </Box>
                </Box>
              </Grid>

              <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      {/* Suma de Líneas de factura / cantidad */}
                      Participación de venta en piezas (cantidad)
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph4 />
                  </Box>
                </Box>
              </Grid>

              {/* <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Suma de Subtotal Costo Producto
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph5 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={6} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Suma de Utilidad
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph6 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={8} lg={12} md={12} sm={12} xs={12}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Promedio de % Utilidad
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph7 />
                  </Box>
                </Box>
              </Grid> */}

              {/* <Grid item xl={4} lg={12} md={8} sm={12} xs={12} sx={{ mx: 'auto' }}>
                <Box className='graph_card'>
                  <Box className='graph_card_top'>
                    <Typography variant='body1' sx={{ color: '#fff' }}>
                      Progreso esperado
                      Progreso global anual de venta
                    </Typography>
                  </Box>
                  <Box sx={{ p: 3 }}>
                    <Graph8 />
                  </Box>
                </Box>
              </Grid> */}

            </Grid>
          </Grid>
        </Grid>

        {/* <Chip label="10/20/2002 - 14/23/0001" onDelete={() => {alert('Borrar')}}  sx={{background: 'red', mb: 3}} /> */}
      </Container>

      <SidebarAdmin
        businessNameDisplay={businessNameDisplay}
        setBusinessNameDisplay={setBusinessNameDisplay}
        expandedData={expandedData}
        setExpandedData={setExpandedData}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
        filterDate={filterDate}
        setFilterDate={setFilterDate}
        dataForDisplay={dataForDisplay}
        setDataForDisplay={setDataForDisplay}
        expanded={expanded}
        setExpanded={setExpanded}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
};

export default IndexAdmin;