import { createContext, useState } from "react";
import { DATASET_GRAPH_FOUR, DATASET_GRAPH_ONE, DATASET_GRAPH_TWO } from "../constants/graph_constants";

export const DashboardContext = createContext();
const { Provider } = DashboardContext;

const DashboardContextProvider = ({ children }) => {
    const [data, setData] = useState({
        businessName: [],
        branches: [],
        products: [],
        categories: [],
        graphs: {
            one: {
                labels: [],
                datasets: [DATASET_GRAPH_ONE]
            },
            two: {
                labels: [],
                datasets: [DATASET_GRAPH_TWO]
            },
            three: {
                labels: [],
                datasets: [DATASET_GRAPH_FOUR]
            },
            four: {
                labels: [],
                datasets: [DATASET_GRAPH_FOUR]
            }
        },
        firstLoading: true,
        backup: {
            businessName: [],
            branches: [],
            products: [],
            categories: [],
            graphs: {
                one: {
                    labels: [],
                    datasets: [DATASET_GRAPH_ONE]
                },
                two: {
                    labels: [],
                    datasets: [DATASET_GRAPH_TWO]
                },
                three: {
                    labels: [],
                    datasets: [DATASET_GRAPH_FOUR]
                },
                four: {
                    labels: [],
                    datasets: [DATASET_GRAPH_FOUR]
                }
            }
        }
    });
    
    return (
        <Provider value={{ data, setData }}>
            { children }
        </Provider>
    );
};

export default DashboardContextProvider;