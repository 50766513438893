//React
import { useContext } from 'react';

//Router dom
import { Navigate } from 'react-router-dom';

//Context
import { RouterContext } from '../Context/RouterContext';

export const ProtectedRoute = ({ children }) => {
    const { item: { email } } = useContext(RouterContext);
    return (email !== '')
        ? children
        : <Navigate to="/" />
};