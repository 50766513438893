import React, { useContext } from 'react';

import { DashboardContext } from '../../../Context/DashboardContext';

import { GRAPH_OPTIONS_1 } from '../../../constants/graph_constants';

//React charts
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

//Data para la gráfica
// const data_dia = {
//     labels: ['Guadalajara', 'Iztapalapa', 'León', 'Oaxaca', 'Querétaro', 'Roma', 'Tlalnepantla', 'Tlalpan', 'Tlaxcala', 'Toluca', 'Tuxtla', 'Valle de Aragón'],
//     datasets: [
//         {
//             label: 'Total',
//             data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 11, 8],
//             backgroundColor: ["#B03A2E", "#BB8FCE", "#85C1E9", "#CD5C5C", "#82E0AA", "#F6DDCC", "#5499C7", '#F7DC6F', '#DC7633', '#34495E', "#F08080", '#196F3D'], 
//             borderColor: ["#B03A2E", "#BB8FCE", "#85C1E9", "#CD5C5C", "#82E0AA", "#F6DDCC", "#5499C7", '#F7DC6F', '#DC7633', '#34495E', "#F08080", '#196F3D'], 
//         },
//     ],
// };

const Graph3 = () => {
    const { data: { graphs: { three } } } = useContext(DashboardContext);
    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Doughnut data={three} options={GRAPH_OPTIONS_1} />
        </div>
    );
};

export default Graph3;