import React, { useContext } from 'react'

import { DashboardContext } from '../../../Context/DashboardContext';

//MUI
import { FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ShowMLButton = styled(Button)(({ theme }) => ({
    color: '#6C7E97',
    textTransform: 'none',
}));

const CompanyNameFilter = ({
    businessNameDisplay, setBusinessNameDisplay,
    expandedData, setExpandedData
}) => {
    const { data: { businessName } } = useContext(DashboardContext);

    // seleccionar razón social de lista mediante checkbox
    const handleCheckboxChange = (business_id) => {
        setBusinessNameDisplay(e => ([
            ...e.map(
                u => u.business_id === business_id ? ({ ...u, checked: !u.checked }) : u
            )
        ]));
    };

    return (
        <>
            <FormGroup>
                {
                    businessNameDisplay.map(({ business_id, business_name, checked }) => (
                            <FormControlLabel key={business_id} 
                            control={
                                <Checkbox
                                    value={ business_id }
                                    checked={ checked }
                                    onChange={ () => handleCheckboxChange(business_id) }
                                />
                            }
                            label={ business_name }
                        />
                    ))
                }
            </FormGroup>
            {
                businessName.length > 7 &&
                    <ShowMLButton onClick={
                        () => setExpandedData(!expandedData)
                    } sx={{ mt: 2 }}>
                        {expandedData ? 'Ver menos' : 'Ver más'}
                    </ShowMLButton>
            }
        </>
    );
};

export default CompanyNameFilter;