import { removeUserSession } from "../helpers/admin/adminHelper";
import { protectedReq, request } from "../helpers/requestHelper";

// validar token de sesión
export const getSession = async (setItem) => {
    try {
        const resp = await protectedReq('sso/get-session');
        const body = await resp.json();
        if (body.status) {
            const { email, username } = body.resp;
            setItem(e => ({ ...e, email, username, loadingSession: false }));
        } else {
            if (['unavailable-token', 'invalid-token'].includes(body.msg))
                removeUserSession(setItem);
            setItem(e => ({ ...e, loadingSession: false }));
        }
    } catch (err) {
        setItem(e => ({ ...e, loadingSession: false }));
    };
};

// guardar token de sesión e información de usuario iniciado
export const oauthToken = async (code, setItem) => {
    try {
        const resp = await request('sso/oauth-token', { code }, 'POST');
        const body = await resp.json();
        if (body.status) {
            const { email, username, token } = body.resp;
            localStorage.setItem('token', token);
            setItem(e => ({ ...e, email, username }));
        }
        return body;
    } catch (err) {
        return { status: false, mgs: 'site-err' };
    };
};