import { Routes, Route } from 'react-router-dom';

//Components
import IndexAdmin from '../Components/Admin/IndexAdmin/IndexAdmin.js';
import HeaderAdmin from '../Components/Admin/HeaderAdmin/HeaderAdmin';

export const AdminRoutes = () => {
    return (
        <>
            <HeaderAdmin />
            <Routes>
                <Route path="/" element={<IndexAdmin />} />
            </Routes>
        </>
    );
};