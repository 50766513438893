import React, { useContext } from 'react';

import { DashboardContext } from '../../../Context/DashboardContext';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const CategoriesFilter2 = ({
    selectedCategory, setSelectedCategory
}) => {
    const { data: { categories } } = useContext(DashboardContext);
    
    const handleChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    return (
        <FormControl>
            <RadioGroup
                defaultValue={1}
                name="categories"
                value={ selectedCategory }
                onChange={ handleChange }
            >
                {
                    categories.map(({ category_id, category }) => (
                        <FormControlLabel
                            key={ category_id }
                            value={ category }
                            control={<Radio />}
                            label={ category }
                        />
                    ))
                }
            </RadioGroup>
        </FormControl>
    );
};

export default CategoriesFilter2;