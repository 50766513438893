import React, { useContext } from 'react';

import { DashboardContext } from '../../../Context/DashboardContext';

import { GRAPH_OPTIONS_1 } from '../../../constants/graph_constants';

//React charts
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const Graph4 = () => {
    const { data: { graphs: { four } } } = useContext(DashboardContext);

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <Pie data={four} options={GRAPH_OPTIONS_1} />
        </div>
    );
};

export default Graph4;