import React, { useState } from 'react'

import './Filters.css'

//MUI
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Components
import DateFilter from './DateFilter';
import ProductsFilter from './ProductsFilter';
import CompanyNameFilter from './CompanyNameFilter';
import CategoriesFilter2 from './CategoriesFilter2';

const Filters = ({
    businessNameDisplay, setBusinessNameDisplay,
    expandedData, setExpandedData,
    filterDate, setFilterDate,
    dataForDisplay, setDataForDisplay,
    expanded, setExpanded,
    selectedCategory, setSelectedCategory
}) => {

    const matches = useMediaQuery('(max-width: 991.5px)')

    const [expand, setExpand] = useState(true)
    const [expand1, setExpand1] = useState(true)
    const [expand2, setExpand2] = useState(true)
    const [expand3, setExpand3] = useState(true)

    const [mainExpanded, setMainExpanded] = useState('panel1');
    const handleChange = (panel) => (event, isExpanded) => {
        setMainExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Accordion
                expanded={matches ? mainExpanded === 'panel3' : expand2}
                onChange={matches ? handleChange('panel3') : () => setExpand2(!expand2)}
                defaultExpanded={matches ? false : true}
                className='filters_accordion'
                disableGutters={matches ? true : false}
            >
                <AccordionSummary sx={{ fontWeight: '600' }}
                    expandIcon={<ExpandMoreIcon />}
                    id="cn_filter"
                >
                    Filtra por razón social
                </AccordionSummary>
                <AccordionDetails className='accord_details'>
                    <CompanyNameFilter
                        businessNameDisplay={businessNameDisplay}
                        setBusinessNameDisplay={setBusinessNameDisplay}
                        expandedData={expandedData}
                        setExpandedData={setExpandedData}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={matches ? mainExpanded === 'panel1' : expand}
                onChange={matches ? handleChange('panel1') : () => setExpand(!expand)}
                defaultExpanded={matches ? false : true}
                className='filters_accordion'
                disableGutters={matches ? true : false}
            >
                <AccordionSummary sx={{ fontWeight: '600' }}
                    expandIcon={<ExpandMoreIcon />}
                    id="date_filter"
                >
                    Filtra por fecha
                </AccordionSummary>
                <AccordionDetails>
                    <DateFilter
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={matches ? mainExpanded === 'panel2' : expand1}
                onChange={matches ? handleChange('panel2') : () => setExpand1(!expand1)}
                defaultExpanded={matches ? false : true}
                className='filters_accordion'
                disableGutters={matches ? true : false}
            >
                <AccordionSummary sx={{ fontWeight: '600' }}
                    expandIcon={<ExpandMoreIcon />}
                    id="products_filter"
                >
                    Filtra por producto
                </AccordionSummary>
                <AccordionDetails className='accord_details'>
                    <ProductsFilter
                        dataForDisplay={dataForDisplay}
                        setDataForDisplay={setDataForDisplay}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={matches ? mainExpanded === 'panel4' : expand3}
                onChange={matches ? handleChange('panel4') : () => setExpand3(!expand3)}
                defaultExpanded={matches ? false : true}
                className='filters_accordion'
                disableGutters={matches ? true : false}
            >
                <AccordionSummary sx={{ fontWeight: '600' }}
                    expandIcon={<ExpandMoreIcon />}
                    id="categories_filter_2"
                >
                    Filtra por categorías
                </AccordionSummary>
                <AccordionDetails className='accord_details'>
                    <CategoriesFilter2
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default Filters;