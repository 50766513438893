import React, { useState } from 'react';

import { Box } from '@mui/material';

//rsuite (Only dateRangePicker)
import DateRangePicker from 'rsuite/DateRangePicker';
import { CustomProvider } from 'rsuite';
import esES from 'rsuite/locales/es_ES';

// (Optional) Import component styles. If you are using Less, import the `index.less` file. 
import 'rsuite/DateRangePicker/styles/index.css';

const DateFilter = ({ filterDate, setFilterDate }) => {
    return (
        <Box>
            <CustomProvider locale={esES}>
                <DateRangePicker size="lg"
                    value={ filterDate }
                    onChange={ setFilterDate }
                />
            </CustomProvider>
        </Box>
    );
};

export default DateFilter;