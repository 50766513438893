import React, { useContext } from 'react';

import { DashboardContext } from '../../../Context/DashboardContext';

//MUI
import { FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const ShowMLButton = styled(Button)(({ theme }) => ({
    color: '#6C7E97',
    textTransform: 'none',
}));

const ProductsFilter = ({
    dataForDisplay, setDataForDisplay,
    expanded, setExpanded
}) => {
    const { data: { products } } = useContext(DashboardContext);

    // seleccionar producto de lista mediante checkbox
    const handleCheckboxChange = (product_id) => {
        setDataForDisplay(e => ([
            ...e.map(
                u => u.product_id === product_id ? ({ ...u, checked: !u.checked }) : u
            )
        ]));
    };

    return (
        <>
            <FormGroup>
                {
                    dataForDisplay.map(({ product_id, product, checked }) => (
                        <FormControlLabel key={ product_id }
                            control={
                                <Checkbox
                                    value={ product_id }
                                    checked={ checked }
                                    onChange={ () => handleCheckboxChange(product_id) }
                                />
                            }
                            label={ product }
                        />
                    ))
                }
            </FormGroup>
            {
                products.length > 7 &&
                    <ShowMLButton onClick={
                        () => setExpanded(!expanded)
                    } sx={{ mt: 2 }}>
                        {expanded ? 'Ver menos' : 'Ver más'}
                    </ShowMLButton>
            }
        </>
    );
};

export default ProductsFilter;